@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/Jost.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Jost.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/Jost.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Jost';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Jost.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/Montserrat.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/Montserrat.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../assets/fonts/Montserrat.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/Montserrat.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: 'Montserrat', sans-serif;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(to bottom right,
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0),
        rgba(1, 65, 255, 0.3));

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(#ffffff80,
        #ffffff40,
        #ffffff30,
        #ffffff20,
        #ffffff10,
        #ffffff10,
        #ffffff80);

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-family: 'Montserrat', sans-serif;
}

/* html,
body {
  width: 100vw;
  min-height: 100vh;F
  overflow-x: hidden;
} */

body {
  color: rgb(var(--foreground-rgb));
  background: white;

  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

@keyframes stage_animation {
  0% {
  transform: translate3d(0%, 0, 0);
  }
  100% {
  transform: translate3d(-100%, 0, 0);
  }
}

.stage_animation {
  animation: stage_animation 10s linear infinite;
}

a {
  color: inherit;
  text-decoration: none;
}

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

@media(max-width: 1024px) {
  .higherPagination .swiper-pagination-bullets {
    bottom: 120px !important;
  }
}

.higherPagination .swiper-pagination-bullets {
  bottom: 120px;
}

.lowerPagination .swiper-pagination-bullets {
  bottom: 20px;
}

.custom-carousel .swiper-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto !important;
  margin-bottom: 100px;
  position: relative;
}

.custom-carousel .swiper-pagination-bullet {
  width: 50px;
  height: 4px;
  background-color: white;
  opacity: 1;
  border-radius: 0;
}

.custom-carousel:hover .swiper-pagination-bullet {
  cursor: pointer;

}

.custom-carousel .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: black;
  border: 2px solid black;
  border-radius: 2px;
}

.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container {
  background-color: white !important;
  border: 0;
  padding: 1rem;
}

.react-datepicker__day--weekend {
  color: red;
}
.react-datepicker__day--weekend.react-datepicker__day--selected {
  color: #fff;
} 

.react-datepicker {
  font-family: inherit;
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15)
}

.select {
  width: 480px;
}

.selectoption {
  width: 480px;
}

.overwritedPickerContainer {
  height: 0;
  position: relative;
}

.overwritedPickerContainer .react-datepicker-wrapper {
  visibility: hidden;
  height: 0;
  position: relative;
  left: 0;
  gap: 10px;
}

.react-datepicker__time-container{
  width: 100% !important;
  padding-left: 23px;
  padding-right: 23px;
}

.react-datepicker__time-container:has(.react-datepicker__time-box) {
  padding-left: 2px;
  padding-right: 2px;
}

.react-datepicker__time-box {
  gap: 10px;
  min-width: 162px;
}

.react-datepicker__time-container {
  background: white;
  border-radius: 1rem;
}

.react-datepicker__header.react-datepicker__header--time.react-datepicker__header--time--only {
  background: transparent;
  border: none;
}


.react-datepicker,
.react-datepicker__month-container {
  border-radius: 1.2rem;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__navigation {
  top: 1.35rem;
}

.react-datepicker__navigation-icon::before {
  border-color: black;
  border-width: 2px 2px 0 0;
  border-radius: 2px;
}

.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container .react-datepicker__header {
  background-color: white;
  border: 0;
}

.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-name {
  color: #6abace;
  text-transform: uppercase;
}


.react-datepicker__time-list::-webkit-scrollbar {
  width: 7px;
}

.react-datepicker__time-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: #888;
}

.react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
  border-bottom: 1px solid #C4C4C4;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.react-datepicker__tab-loop .react-datepicker {
  border: 0;
}

.react-datepicker__input-container input[type="text"] {
  padding: .75rem 1rem;
  border-radius: 1rem;
  outline: none;
  color: black;
  width: 100%;
}

.underlineLink a{
  text-decoration: underline;
  color: #6ABACE
}

.react-datepicker__day--disabled.react-datepicker__day--weekend {
  color: #CCCCCC
}

.bullet-style li {
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 20px;
}

.bullet-style ul {
  padding-top: 10px;
  padding-bottom: 10px;
}

.react-player {
  overflow: hidden;
  border-radius: 15px;
}

.react-datepicker__day--outside-month{
  color: #CCCCCC;
  pointer-events: none;
}

/* .react-datepicker__day--selected{
  color: black !important
} */

.react-datepicker__navigation--years-previous {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 1px;
  transform: scale(0.4) rotate(45deg);
  position:relative;
  top: 0;
}

.react-datepicker__navigation--years-upcoming {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 1px;
  transform: scale(0.4) rotate(-135deg);
  position:relative;
  top: 0;
}

.grecaptcha-badge { visibility: hidden; }

.box-alert .swiper-wrapper {
  height: initial;
  position: relative;
}

.box-alert .swiper-slide {
  position: relative;
}

/* custom menu language MUI */

.MuiPaper-root {
  border-radius: 0 !important;
}

.MuiPaper-root:has(.language-item) {
  padding: 0;
}

@media(min-width: 600px) {
  .MuiPaper-root:has(.language-item) {
    border-top: 6px solid #6ABACE;
  }
}

.MuiInputBase-root.language-select {
  border-radius: 0;
}

.MuiInputBase-root.language-select:hover {
  border: none;
  outline: none;
}

.MuiInputBase-root.language-select::before,
.MuiInputBase-root.language-select::after {
  border: 0;
}

.language-select .MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

/* end custom language menu MUI */
.cont-news {
  ul{
    margin: 40px 0;
    list-style: initial;
    padding-left: 20px;
    li {
      line-height: 1.4;
      margin: 6px 0;
    }
  }
}
