.slideTitle{
    font-weight: 700;
   /*  color: white; */
    text-transform: uppercase;
    font-size: 33px;
    line-height: 35px;
    max-width: 66%;
    margin-top: 6px;
}

.slideSubtitle{
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
}

@media (min-width: 1024px){
    .slideTitle{
        font-weight: 600;
       /*  color: white; */
        text-transform: uppercase;
        font-size: 48px;
        line-height: 50px;
        max-width: 33%;
    }

    .slideSubtitle{
        color: white;
        font-size: 24px;
        font-weight: 400;
        line-height: 29px;
    }
}

.slideItem{
    /* padding: 35px;
    width: 100%; */
    height: 475px;
    background-repeat: no-repeat;
    background-size: cover;
}


.extContainer{
    position: absolute;
    bottom: -103px;
    z-index: 2;
}

@media(max-width: 1024px){
    .extContainer{
        position: absolute;
        bottom: -265px;
        z-index: 2;
    }
}


.carouselSectionWrapper{
    position: relative;
    margin-bottom: 40px;
}

/* 
.high{
    height: 475px;
}

.medium{
    height: 305px;
} */

/* .swiper-pagination-bullet */.customBullet{
    width: 20px !important;
    height: 4px !important;
    /* background-color: black !important; */
}