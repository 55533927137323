.dropdownWrapper{
  border-color: red;
  border: 1px solid black;
}


select.dropdownWrapper{
  border-radius: 0.6rem;
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #fff;
  color: #000;
  min-width: 250px;
}

select.dropdownWrapper option {
  /* Proprietà delle opzioni */
  border-radius: 1rem;
  background-color: #fff;
  color: #000;
}


select.dropdownWrapper:focus {
  /* Proprietà quando il selettore ha lo stato di focus */
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}
