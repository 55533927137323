/* width */
.customSelect ::-webkit-scrollbar {
    width: 10px !important; 
  }
  
  /* Track */
  .customSelect ::-webkit-scrollbar-track {
    background: #f1f1f1 !important; 
  }
   
  /* Handle */
  .customSelect ::-webkit-scrollbar-thumb {
    background: #888 !important; 
  }
  
  /* Handle on hover */
  .customSelect ::-webkit-scrollbar-thumb:hover {
    background: #555 !important; 
  }
  
