.customPickerWrapper .react-datepicker__tab-loop .react-datepicker .react-datepicker__month-container{
    background-color: red !important;
}

.customPickerWrapper{
    background-color: blue;
    border: 1px solid green;
}

.customPicker{
    
}

.customRadio{
    width: 24px;
    height: 24px;
    background: transparent;
    border: 1px solid black;
    border-radius: 2rem;
}

.selectedItem{
    display: none;
    width: .5rem;
    height: .5rem;
    background-color: white;
    border-radius: 50%;
}

.activeRadio{
    display: block !important;
}


.customTextInputWrapper {
    position: relative;
}

.icon{
    height: 1.5rem;
    width: 1.5rem;
    background-color: red;
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top:50%;
    left:2px;
    transform: translateY(-50%);
}

.customTextInput{
    height: 50px;
    box-sizing: border-box;
    padding-left: 1.5rem;
}
