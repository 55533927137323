/* width */
.searchResult::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .searchResult::-webkit-scrollbar-track {
    background: #c4c4c4;
  }
  
  /* Handle */
  .searchResult::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
  }
  
