.navItem{
    /* max-height: 29px; */
    height:100%;
    position: relative;
    overflow: hidden;
}

.navItem .decorator{
    width: 100%;
    height: 4px;
    position: absolute;
    bottom: 0;
    background: #6ABACE;
    display: none;
}


.navItem.active .decorator, .navItem:hover .decorator{
    display: block;
}

.navItemsContainer{
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
}

.customShadow{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
}